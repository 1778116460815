import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_attributes
  };
};
export const structure = () => [  {
  head: "",
  width: "35%",
  list: [
    {
      name: `image`,
      kind: "upload",
      // type: "text",
      label: "Sub category photo",
      width: "100%",
      // placeholder:"Sub category name (AR)"

    },
  ],
},

  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Sub-category name (EN)",
        width: "47%",
        placeholder:"Sub category name (EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Sub-category name (AR)",
        width: "47%",
        placeholder:"Sub category name (AR)"

      },
      {
        name: `main_category`,
        kind: "select",
        type: "text",
        label: "Select main category",
        width: "47%",
        options:[{id:1,name:'Land'},{id:2,name:'Air'},{id:3,name:'Water'}]
      },
    ],
  },
];

export const validationSchema = () =>
Yup.object().shape({
  en: Yup.object().shape({
    name: Yup.string().required("required"),
  }),
  ar: Yup.object().shape({
    name: Yup.string().required("required"),
  }),
  main_category: Yup.lazy(value => { // because here without changing it in edit it will be an object , once you change it the value becomes a str
    switch (typeof value) {
      case 'object':
        return Yup.object().required("required"); // schema for object
      case 'string':
        return Yup.string().required("required")
      default:
        return Yup.mixed(); // here you can decide what is the default
    }
  }),
});
